import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { COOKIES_KEY } from '../../config/keys-storage';
import { WrapperCookieService } from '../wrapper-cookie.service';
import {
	IRequestApiConfirmSignUp,
	IRequestApiSignUp,
	IRequestApiconfirmForgotPassword,
	IResponseApiConfirmForgotPassword,
	IResponseApiConfirmSignUp,
	IResponseApiGetIdUser,
	IResponseApiResend,
	IResponseApiSignCodeToken,
	IResponseApiSignIn,
	IResponseApiSignSocial,
	IResponseApiSignUp,
	IresponseApiSignOut
} from './models/auth-api.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(private http: HttpClient, private cookieService: WrapperCookieService) {}

	signUp(user: IRequestApiSignUp): Observable<IResponseApiSignUp> {
		return this.http.post<IResponseApiSignUp>(environment.auth.signup, user);
	}

	confirmSignUp(request: IRequestApiConfirmSignUp) {
		return this.http.post<IResponseApiConfirmSignUp>(environment.auth.signupConfirm, request);
	}

	resendConfirmSignUp(email: string): Observable<IResponseApiResend> {
		const params = new HttpParams().set('email', email);
		return this.http.get<IResponseApiResend>(environment.auth.confirmResend, { params });
	}

	signInSocial(provider: string): Observable<IResponseApiSignSocial> {
		const params = new HttpParams().set('provider', provider);
		return this.http.get<IResponseApiSignSocial>(environment.auth.federatedSignin, { params });
	}

	signInCodeToken(code: string): Observable<IResponseApiSignCodeToken> {
		const params = new HttpParams().set('code', code);
		return this.http.get<IResponseApiSignCodeToken>(environment.auth.confirmResend, { params });
	}

	signIn(email: string, password: string) {
		return this.http.post<IResponseApiSignIn>(environment.auth.signin, { email, password });
	}

	getIdUser(token: string): Observable<IResponseApiGetIdUser> {
		var headers = {
			Authorization: 'Bearer ' + token
		};
		return this.http.get<IResponseApiGetIdUser>(environment.auth.getIdUserCognito, { headers: headers });
	}

	refreshToken(): Observable<IResponseApiSignIn> {
		let id = this.cookieService.get<string>(COOKIES_KEY.USER_ID);
		let refreshToken = this.cookieService.get<string>(COOKIES_KEY.REFRESH_TOKEN);

		return this.http.post<IResponseApiSignIn>(
			`${environment.auth.refreshToken}?user=${id}&refresh_token=${refreshToken}`,
			null
		);
	}

	forgotPassword(email: string) {
		var parts = email.split('@');
		const url = `${environment.auth.forgotPassword}?email=${parts[0]}%40${parts[1]}`;

		var headers = {
			accept: 'application/json'
		};

		return this.http.get(url, { headers: headers });
	}

	confirmForgotPassword(request: IRequestApiconfirmForgotPassword) {
		return this.http.post<IResponseApiConfirmForgotPassword>(environment.auth.confirmForgotPassword, request);
	}

	signOut(): Observable<IresponseApiSignOut> {
		return this.http.post<IresponseApiSignOut>(environment.auth.signOut, {});
	}
}
