import { Routes } from '@angular/router';
import { AuthGuard } from './commons/guards/auth.guard';

export const APP_ROUTES: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/auth/auth.routes')
	},
	{
		path: 'login',
		redirectTo: '',
		pathMatch: 'full'
	},
	{
		path: 'admin',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/admin/admin.routes')
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full'
	}
];
