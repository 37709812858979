import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { SubscriptionType } from './api/wallet/models/subscriptions-api';

export interface CurrentURL {
	origin: string;
	pathname: string;
	href: string;
}

@Injectable({
	providedIn: 'root'
})
export class UtilService {
	private lang!: string;

	private routeId$ = new Subject<string>();
	public routeId: string = '';

	public isOpen = true;

	private _toggleHeaderRouterSource = new BehaviorSubject<'LEFT' | 'RIGHT'>('LEFT');

	purchaseView = false;
	subscriptionToPurchase: SubscriptionType | null = null;
	toggleHeaderRouter$ = this._toggleHeaderRouterSource.asObservable();

	constructor(private router: Router) {}

	toggleHeaderRouter(toggle: 'LEFT' | 'RIGHT') {
		this._toggleHeaderRouterSource.next(toggle);
	}

	setrouteId(value: string) {
		this.routeId$.next(value);
	}

	getrouteId() {
		return this.routeId$.asObservable();
	}

	get navigatorLanguage() {
		const ln = navigator.language;

		if (ln.includes('pt')) {
			this.lang = 'pt';
		}

		if (ln.includes('es')) {
			this.lang = 'es';
		}

		if (ln.includes('en')) {
			this.lang = 'en';
		}

		return this.lang;
	}

	get currentLanguage() {
		return this.lang;
	}

	get mktRouteValidation() {
		return this.router.url.includes('marketplace') ? true : false;
	}

	get activeRoute() {
		var response = {
			url: '',
			title: '',
			activeSubtitle: '',
			activeMenu: ''
		};
		var array = this.router.url.split('/');

		response.url = this.router.url;
		response.title = array[2];
		if (array.length > 2) {
			response.activeSubtitle = array[3];
		}

		if (array.length > 3) {
			response.activeMenu = array[4] == 'dashboard' ? 'wallet' : array[4];
		}

		return response;
	}

	get isMenuOpen() {
		return this.isOpen;
	}
}
