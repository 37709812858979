import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

interface IStorageValue<T> {
	value: T;
}

@Injectable({ providedIn: 'root' })
export class WrapperCookieService {
	private _cookieService = inject(CookieService);
	set(name: string, value: unknown): void {
		let data = JSON.stringify({ value });

		this._cookieService.set(name, data, undefined, '/');
	}

	get<T>(key: string): T | undefined {
		const value = this._cookieService.get(key);
		if (value) {
			const storageValue = JSON.parse(value) as IStorageValue<T>;
			return storageValue.value;
		}

		return undefined;
	}

	deleteAll(): void {
		this._cookieService.deleteAll('/');
	}

	delete(key: string): void {
		this._cookieService.delete(key, '/');
	}
}
