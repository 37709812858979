import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { COOKIES_KEY } from '../config/keys-storage';
import { AuthService } from '../services/api/auth.service';
import { WrapperCookieService } from '../services/wrapper-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService, private cookieService: WrapperCookieService) {}

  redirect(flag: boolean): void {
    if (!flag) {
      this.router.navigate(['/', 'ingresar']);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const code = route.queryParams['code'];
    if (code) {
      const currentUrl = state.url;
      const [baseUrl] = currentUrl.split('?');
      return this._signInCodeToken(code, baseUrl);
    }

    const token = this.cookieService.get<string>(COOKIES_KEY.ACCESS_TOKEN);
    if (!token) {
      this.redirect(false);
      return false;
    }

    return this.authService.getIdUser(token).pipe(
      map((resp: any) => {
        if (!resp.data) {
          this.redirect(false);
          return false;
        }
        return true;
      }),
      catchError((error: any) => {
        console.log('Error al obtener el ID de usuario:', error);
        this.redirect(false);
        return of(false);
      })
    );
  }

  private _signInCodeToken(code: string, cleanUrl: string): Observable<boolean> {
    return this.authService.signInCodeToken(code).pipe(
      map((response) => {
        this.cookieService.set(COOKIES_KEY.ACCESS_TOKEN, response.data.AccessToken);
        this.cookieService.set(COOKIES_KEY.REFRESH_TOKEN, response.data.RefreshToken);
        this.cookieService.set(COOKIES_KEY.USER_ID, response.data.UserId);
        this.router.navigateByUrl(cleanUrl);
        return true;
      }),
      catchError(() => {
        this.router.navigate(['/', 'ingresar']);
        return of(false);
      })
    );
  }
}
