import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { COOKIES_KEY } from '../config/keys-storage';
import { WrapperCookieService } from './../services/wrapper-cookie.service';

const EXCLUDE_URL = ['/auth/'];

const _isExclude = (url: string) => {
	//not exclude LOGOUT
	if (url.search('signout') > -1) {
		return false;
	}

	const urlExits = EXCLUDE_URL.find((path) => url.search(path) > -1);
	return urlExits !== undefined;
};

const _isAssets = (url: string): boolean => {
	return url.search('assets/') > -1;
};

export const ApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const _cookieService = inject(WrapperCookieService);
	const _router = inject(Router);

	if (_isAssets(request.url) || _isExclude(request.url)) {
		return next(request);
	}

	const _token = _cookieService.get(COOKIES_KEY.ACCESS_TOKEN);
	if (!_token) {
		_router.navigateByUrl('/login');
		return EMPTY;
	}

	const _requestClone = request.clone({ headers: request.headers.set('Authorization', `Bearer ${_token}`) });

	return next(_requestClone);
};
