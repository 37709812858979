import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, catchError, concatMap, retry, throwError } from 'rxjs';
import { COOKIES_KEY } from '../config/keys-storage';
import { AuthService } from '../services/api/auth.service';
import { WrapperCookieService } from '../services/wrapper-cookie.service';

const RECONNECT_DELAY = 2000;
const RETRY_NUMBER = 3;
let _retriesTimedOut = false;
let _reconnecting = false;

const _redirectAndClear = () => {
	const _cookieService = inject(WrapperCookieService);
	const _router = inject(Router);

	_retriesTimedOut = false;
	_reconnecting = false;
	_cookieService.deleteAll();
	console.info('Redireccionando al Login....');
	_router.navigateByUrl('/ingresar');
};

const _reconnect = (refreshToken: string, request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const newRequest = request.clone({
		setHeaders: {
			Authorization: `Bearer ${refreshToken}`
		}
	});

	return next(newRequest).pipe(
		retry({ count: RETRY_NUMBER, delay: RECONNECT_DELAY }),
		catchError((_) => {
			console.error('Se ha alcanzado el máximo de reintentos...');
			_retriesTimedOut = true;
			_redirectAndClear();
			return EMPTY;
		})
	);
};

const _refreshToken = (refreshToken: string, request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const _cookieService = inject(WrapperCookieService);
	const _authService = inject(AuthService);

	return _authService.refreshToken().pipe(
		retry({ count: RETRY_NUMBER, delay: RECONNECT_DELAY }),
		concatMap((response) => {
			_cookieService.delete(COOKIES_KEY.ACCESS_TOKEN);
			_cookieService.set(COOKIES_KEY.ACCESS_TOKEN, response.data.AccessToken);
			_reconnecting = true;
			return _reconnect(refreshToken, request, next);
		}),
		catchError((_) => {
			console.log('Intente más tarde...');
			_redirectAndClear();
			return EMPTY;
		})
	);
};

export const ErrorApiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
	const _cookieService = inject(WrapperCookieService);

	return next(request).pipe(
		catchError((error: HttpErrorResponse) => {
			if (_reconnecting) {
				console.info('En proceso de reconexion...');
				return EMPTY; // Evitar la reconexión mientras ya se está reconectando
			}

			if (_retriesTimedOut) {
				console.error('Reintentos agotados, intente más tarde');
				return EMPTY;
			}

			if (error.status === 401 && error.statusText === 'Unauthorized') {
				// Realiza la lógica para obtener el nuevo "refresh token"
				const refreshToken = _cookieService.get<string>(COOKIES_KEY.REFRESH_TOKEN);

				if (refreshToken) {
					return _refreshToken(refreshToken, request, next);
				}
			}

			return throwError(() => error);
		})
	);
};
