import { EnvCfgType } from './types';

const getSvcConfiguration = (service: string, envName: string, isWebSocket = false) =>
	`${isWebSocket ? 'wss' : 'https'}://${service}.svc.${envName}.4id.network`;

export const commonEnvironment = (envName: string, isLocal = false): EnvCfgType => {
	return {
		auth: {
			signin: `${isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)}/auth/signin`,
			signup: `${isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)}/auth/signup`,
			signupConfirm: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/signup/confirm`,
			confirmResend: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/federated/signin/token`,
			federatedSignin: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/federated/signin`,
			forgotPassword: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/password/forgot`,
			confirmForgotPassword: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/password/forgot/confirm`,
			getIdUserCognito: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/cognitouser`,
			refreshToken: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/refresh-tokens`,
			signOut: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/global/signout`,
			validateHash: `${
				isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)
			}/auth/password/forgot/validate/`,
			validateEmail: `${isLocal ? import.meta.env.NG_APP_AUTH_PATH : getSvcConfiguration('auth', envName)}/auth/verify/`
		},
		user: {
			path: isLocal ? import.meta.env.NG_APP_USER_PATH : getSvcConfiguration('user', envName)
		},
		wallet: {
			path: isLocal ? import.meta.env.NG_APP_WALLET_PATH : getSvcConfiguration('pay', envName),
			balances: `${
				isLocal ? import.meta.env.NG_APP_WALLET_PATH : getSvcConfiguration('pay', envName)
			}/balances/$user_id/coins`,
			paymentMethods: {
				cards: `${
					isLocal ? import.meta.env.NG_APP_WALLET_PATH : getSvcConfiguration('pay', envName)
				}/payment_methods/$customer_id/cards`,
				cardsToken: `${
					isLocal ? import.meta.env.NG_APP_WALLET_PATH : getSvcConfiguration('pay', envName)
				}/payment_methods/$customer_id/cards-tokens`
			}
		},
		chat: {
			path: `${isLocal ? import.meta.env.NG_APP_CHAT_PATH : getSvcConfiguration('chat', envName)}/chats`,
			webSockets: {
				messengers: `${
					isLocal ? import.meta.env.NG_APP_WEBSOCKET_PATH : getSvcConfiguration('chat', envName, true)
				}/messengers`
			}
		},
		stripe: 'https://api.stripe.com',
		// fairaccess: 'http://localhost:8080'
		fairaccess: 'https://fa.svc.stage.4id.network'
	};
};
