import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/cryptojs.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/edit_in_popup.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/forms.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import { register } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { ApiInterceptor } from './app/commons/interceptors/api.interceptor';
import { ErrorApiInterceptor } from './app/commons/interceptors/error-api.interceptor';

const httpTranslateLoader = (http: HttpClient) => {
	return new TranslateHttpLoader(http);
};
// register Swiper custom elements
register();

bootstrapApplication(AppComponent, {
	providers: [
		provideRouter(APP_ROUTES, withComponentInputBinding()),
		provideAnimations(),
		provideHttpClient(withInterceptors([ApiInterceptor, ErrorApiInterceptor])),
		importProvidersFrom(
			TranslateModule.forRoot({
				defaultLanguage: 'es',
				loader: {
					provide: TranslateLoader,
					useFactory: httpTranslateLoader,
					deps: [HttpClient]
				}
			}),
			MatDialogModule,
			MatSnackBarModule
		)
	]
}).catch((err) => console.error(err));
